































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/lib-api';
import { DevicesAction, DevicesActionType, DevicesState } from '@/store/modules/devices/types';
import { AppAction, AppActionType, DrawerTab } from '@/store/modules/app/types';
import { CircuitsAction, CircuitsActionType, CircuitsState } from '@/store/modules/circuits/types';
import { nameFilter } from '@/utils/name_filter';

const circuits = namespace('circuits');
const devices = namespace('devices');
const app = namespace('app');

@Component
export default class CircuitActionsCard extends Vue {
  @Prop(Object) readonly position!: object;

  isOpen = false;

  show = false;

  searchPhrase = '';

  pendingCircuitAction = false;

  clickedCircuitName: string | null = null;

  @circuits.State
  circuits!: CircuitsState['circuits'];

  @devices.State
  insidePolygonMarkers!: DevicesState['insidePolygonMarkers'];

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB'];

  @app.Action(AppAction.SetMapSelectionEnabled)
  setMapSelectionEnabled!: AppActionType['SET_MAP_SELECTION_ENABLED'];

  @app.Action(AppAction.ToggleCircuitActionsCard)
  toggleCircuitActionsCard!: AppActionType['TOGGLE_CIRCUIT_ACTIONS_CARD'];

  @circuits.Action(CircuitsAction.AddCircuitPlaceholder)
  AddCircuitPlaceholder!: CircuitsActionType['ADD_CIRCUIT_PLACEHOLDER'];

  @devices.Action(DevicesAction.AddDeviceToCircuit)
  addDeviceToCircuit!: DevicesActionType['ADD_DEVICE_TO_CIRCUIT'];

  @devices.Action(DevicesAction.SetInsidePolygonMarkers)
  setInsidePolygonMarkers!: DevicesActionType['SET_INSIDE_POLYGON_MARKERS'];

  get filteredCircuits(): Array<Models.Circuits.Model> {
    return this.circuits.filter(((c) => nameFilter(c.name, this.searchPhrase)));
  }

  async addToCircuit(circuit: Models.Circuits.Model) {
    this.pendingCircuitAction = true;
    this.isOpen = false;
    this.clickedCircuitName = circuit.name;
    const markers = this.insidePolygonMarkers;
    // eslint-disable-next-line no-restricted-syntax
    for (const m of markers) {
      // eslint-disable-next-line no-await-in-loop
      await this.addDeviceToCircuit({
        uuid: m.uuid as string,
        circuitId: circuit.uuid as string,
        controllerType: m.controller_type
      });
    }
    this.pendingCircuitAction = false;
    this.backToCreator();
    this.setInsidePolygonMarkers([]);
  }

  createNewCircuit() {
    this.AddCircuitPlaceholder();
    this.backToCreator();
  }

  toggleExpand() {
    this.isOpen = !this.isOpen;
  }

  backToCreator() {
    this.setMapSelectionEnabled(false);
    this.toggleCircuitActionsCard(false);
    this.setSelectedTab(DrawerTab.Creator);
  }
}
